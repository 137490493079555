import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';
import { Box, Grid, TextField, Typography, List, ListItem, ListItemText } from '@material-ui/core';
import KeyValueListItem from './KeyValueListItem';
import { green } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  schedule: {
    borderRadius: 5,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: grey[300],
    margin: 4,
  },
  scheduleTitle: {
    backgroundColor: grey[100],
    fontWeight: 'bold',
  },
  scheduleActive: {
    backgroundColor: green[100],
    fontWeight: 'bold',
  },
  entries: {
    margin: 0,
  },
  entry: {
    margin: 3,
    padding: 2,
    borderRadius: 5,
    borderWidth: 1,
    borderStyle: 'dashed',
    borderColor: grey[300],
  },
  entryValue: {
    padding: 0,
  },
}));

export const TouDetails = ({ className, tou, touText, setTOUText }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      {/* Left Box: Info */}
      <Grid item xs={6}>
        <Box
          sx={{
            padding: 2,
            border: '1px solid #ccc',
            borderRadius: 1,
            height: '100%',
          }}
        >
          <Typography variant="h6">Info</Typography>
          {/* Loop through the tou object and render the keys and values */}
          {Boolean(tou) && (
            <List>
              <KeyValueListItem
                keyText="Enabled"
                value={(tou.config.enabled && 'Enabled') || 'Disabled'}
              />
              <KeyValueListItem keyText="Timezone" value={tou.config.timezone} />
              <KeyValueListItem
                keyText="Active Schedules"
                value={tou.config.active_schedules.join(' & ')}
              />
              {tou.schedules?.map((schedule, index) => (
                <ListItem key={index} className={classes.schedule}>
                  <List style={{ width: '100%' }}>
                    <ListItem
                      className={
                        (tou.config.active_schedules.includes(index + 1) &&
                          classes.scheduleActive) ||
                        classes.scheduleTitle
                      }
                    >
                      <ListItemText>
                        {index + 1}: {schedule.name} / {schedule.repeat} / {schedule.type}
                      </ListItemText>
                    </ListItem>
                    {schedule.entries?.map((entry, entry_index) => (
                      <ListItem
                        className={classes.entry}
                        key={`entry-${index}-${entry_index}`}
                        fullWidth
                      >
                        <List style={{ width: '100%' }}>
                          {Boolean(entry.time_of_day) && (
                            <KeyValueListItem keyText="Time of day" value={entry.time_of_day} />
                          )}
                          {Boolean(entry.mode_name) && (
                            <KeyValueListItem keyText="Mode" value={entry.mode_name} />
                          )}
                          {Boolean(entry.day_of_year) && (
                            <KeyValueListItem keyText="Day of year" value={entry.day_of_year} />
                          )}
                          {Boolean(entry.power_limit) && (
                            <KeyValueListItem
                              keyText="Power Limit"
                              value={String(entry.power_limit) + '%'}
                            />
                          )}
                          {Boolean(entry.active_schedules) && (
                            <KeyValueListItem
                              keyText="Active Schedules"
                              value={entry.active_schedules.join(' & ')}
                            />
                          )}
                        </List>
                      </ListItem>
                    ))}
                  </List>
                </ListItem>
              ))}
            </List>
          )}
        </Box>
      </Grid>

      {/* Right Box: TextBox with stringified tou */}
      <Grid item xs={6}>
        <Box
          sx={{
            padding: 2,
            border: '1px solid #ccc',
            borderRadius: 1,
            height: '100%',
            width: '200px',
          }}
        >
          <Typography variant="h6">Text</Typography>
          <TextField
            multiline
            fullWidth
            variant="outlined"
            value={touText}
            onChange={(e) => setTOUText(e.target.value)}
            sx={{
              marginTop: 1,
              height: '100%',
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

TouDetails.propTypes = {
  className: PropTypes.string,
  tou: PropTypes.any.isRequired,
  touText: PropTypes.string.isRequired,
};

TouDetails.defaultProps = {
  touText: '',
};
